<template>
  <div class="container">
    <h1>Privacyverklaring</h1>
    <p>
      Etenbijsindy.nl is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.
  </p>
  <h2>Contactgegevens:</h2>
  <p>
  https://www.etenbijsindy.nl<br>
  Te bereiken via info@codulo.nl
  </p>

  <h2>Persoonsgegevens die wij verwerken</h2>
  <p>Etenbijsindy.nl verwerkt je persoonsgegevens doordat je gebruik maakt van onze diensten en/of omdat je deze zelf aan ons verstrekt.</p>

  <p>Hieronder vind je een overzicht van de persoonsgegevens die wij verwerken:<br>
  - E-mailadres bij inschrijving op de nieuwsbrief<br>
  </p>

  <h2>Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h2>
  <p>Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als je er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via info@codulo.nl, dan verwijderen wij deze informatie.</p>

  <h2>Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</h2>
  <p>Etenbijsindy.nl verwerkt jouw persoonsgegevens voor de volgende doelen:<br>
  - Verzenden van onze nieuwsbrief waarin je op de hoogte wordt gehouden van nieuwe recepten
  </p>

  <h2>Geautomatiseerde besluitvorming</h2>
  <p>Etenbijsindy.nl heeft geen processen op basis van geautomatiseerde verwerkingen die besluiten over zaken die (aanzienlijke) gevolgen kunnen hebben voor personen. Het gaat hier om besluiten die worden genomen door computerprogramma's of -systemen, zonder dat daar een mens (bijvoorbeeld een medewerker van Etenbijsindy.nl) tussen zit.
  </p>

  <h2>Hoe lang we persoonsgegevens bewaren</h2>
  <p>Etenbijsindy.nl bewaart je persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor je gegevens worden verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende (categorieën) van persoonsgegevens:<br>
  - E-mailadres: tot wederopzegging van je nieuwsbriefabonnement
  </p>

  <h2>Delen van persoonsgegevens met derden</h2>
  <p>Etenbijsindy.nl verkoopt jouw gegevens niet aan derden en zal deze uitsluitend verstrekken indien dit nodig is voor de uitvoering van onze overeenkomst met jou of om te voldoen aan een wettelijke verplichting. Met bedrijven die jouw gegevens verwerken in onze opdracht, sluiten wij een bewerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van jouw gegevens. Etenbijsindy.nl blijft verantwoordelijk voor deze verwerkingen.</p>

  <p>Etenbijsindy.nl deelt jouw persoonsgegevens uitsluitend aan derden als dit noodzakelijk is voor het uitvoeren van de overeenkomst en om te voldoen aan een eventuele wettelijke verplichting. Met bedrijven die je gegevens verwerken in onze opdracht, sluiten wij een verwerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van jouw gegevens. Etenbijsindy.nl blijft verantwoordelijk voor deze verwerkingen. Daarnaast verstrekt Etenbijsindy.nl jouw persoonsgegevens aan andere derden. Dit doen wij alleen met jouw nadrukkelijke toestemming.</p>

  <p>Partijen waarmee wij samenwerken:<br>
    - Mailchimp
  </p>

  <!-- <h2>Cookies, of vergelijkbare technieken, die wij gebruiken</h2>
  <p>Etenbijsindy.nl gebruikt alleen technische en functionele cookies en analytische cookies die geen inbreuk maken op je privacy. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen op jouw computer, tablet of smartphone. De cookies die wij gebruiken zijn noodzakelijk voor de technische werking van de website en jouw gebruiksgemak. Ze zorgen ervoor dat de website naar behoren werkt en onthouden bijvoorbeeld jouw voorkeursinstellingen. Ook kunnen wij hiermee onze website optimaliseren. Je kunt je afmelden voor cookies door je internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kun je ook alle informatie die eerder is opgeslagen via de instellingen van je browser verwijderen.</p> -->

  <h2>Cookies, of vergelijkbare technieken, die wij gebruiken</h2>
  <p>Etenbijsindy.nl gebruikt geen cookies of vergelijkbare technieken.. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen in de browser van je computer, tablet of smartphone. Cookies met een puur technische functionaliteit zorgen ervoor dat de website naar behoren werkt en dat bijvoorbeeld jouw voorkeursinstellingen onthouden worden. Deze cookies worden ook gebruikt om de website goed te laten werken en deze te kunnen optimaliseren.</p>

  <p>Je kunt je afmelden voor cookies door je internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kun je ook alle informatie die eerder is opgeslagen via de instellingen van je browser verwijderen.</p>

  <p>Zie voor een toelichting: https://veiliginternetten.nl/themes/situatie/cookies-wat-zijn-het-en-wat-doe-ik-ermee/</p>

  <h2>Gegevens inzien, aanpassen of verwijderen</h2>
  <p>Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heb je het recht om je eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens door Etenbijsindy.nl en heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek kan indienen om de persoonsgegevens die wij van jou beschikken in een computerbestand naar jou of een ander, door jou genoemde organisatie, te sturen.</p>

  <p>Je kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van je persoonsgegevens of verzoek tot intrekking van je toestemming of bezwaar op de verwerking van jouw persoonsgegevens sturen naar info@codulo.nl.</p>

  <p>We reageren zo snel mogelijk, maar binnen vier weken, op jouw verzoek.</p>

  <p>Etenbijsindy.nl wil je er tevens op wijzen dat je de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons</p>

  <h2>Hoe wij persoonsgegevens beveiligen</h2>
  <p>Etenbijsindy.nl neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op via info@codulo.nl</p>

  <h2>Hoe wij persoonsgegevens beveiligen</h2>
  <p>Etenbijsindy.nl neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er zijn aanwijzingen van misbruik, neem dan contact op via info@codulo.nl.</p>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/colors.scss';

.container {
  background-color: $primaryColor;
  border-radius: 16px;
  color: #fff;
  padding: 24px;
  margin-top: 3em;
  margin-bottom: 1em;

  h1 {
    @include headerFont;
    text-align: center;
    margin-bottom: 0;
  }

  h2 {
    @include headerFont;
    width: 75%;
    text-align: center;
    margin: 0 auto;
  }

  p {
    width: 75%;
    margin: 1em auto;
    text-align: center;
  }

  ul {
    width: 90%;
    margin: 0 0 2em;

    @include respond-to('xsmall') {
      padding: 0 1em;
    }

    @include respond-to('small') {
      width: 75%;
      margin: 0 auto 2em;
    }
  }
}
</style>

<script>
import titleMixin from '@/assets/mixins/titleMixin'

export default {
  name: 'Privacyverklaring',
  components: {},
  mixins: [titleMixin],
  title () {
    return 'Privacyverklaring – Eten Bij Sindy'
  }
}
</script>
