<template>
  <li
    @click="toggleIngredient(ingredient)"
    @mouseenter="hoverIngredient(ingredient, true)"
    @mouseleave="hoverIngredient(ingredient, false)"
    :class="{ crossed: ingredient.done }"
    itemprop="recipeIngredient"
  >
    <i :class="setCheckboxStatus(ingredient)"></i>
    <b>{{ ingredient.amount }} {{ ingredient.unit }}</b>
    {{ ingredient.name }}<span class="extra-info" v-if="ingredient.extraInfo">&nbsp;{{ ingredient.extraInfo }}</span>
    <i v-if="ingredient.prep">– {{ ingredient.prep }}</i>
  </li>
</template>

<script>
export default {
  name: 'IngredientListItem',
  props: {
    ingredient: Object
  },
  methods: {
    toggleIngredient (ingredient) {
      ingredient.done = !ingredient.done
    },
    hoverIngredient (ingredient, status) {
      ingredient.hover = status
    },
    setCheckboxStatus (ingredient) {
      if (ingredient.hover) {
        return 'fas fa-check-square'
      } else if (ingredient.done) {
        return 'fas fa-check-square'
      } else {
        return 'far fa-square'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors.scss';

li {
  border-bottom: 1px dashed #ccc;
  line-height: 2.25;
  cursor: pointer;

  i {
    margin: 0 9px 0 5px;
  }

  .extra-info {
    color: #777;
  }
}

.crossed {
  text-decoration: line-through;
}
</style>
