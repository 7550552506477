<template>
  <form class="search-form" action="" type="submit">
    <input type="text" v-model="query" placeholder="Zoeken..." />
    <button type="submit"><i class="fa fa-search"></i></button>
  </form>

  <div class="recipe-list">
    <template v-for="(recipe, i) in foundRecipes" v-bind:key="i">
      <RecipeCard :recipe="recipe" />
    </template>
  </div>
</template>

<script>
import RecipeCard from '@/components/RecipeCard.vue'
import titleMixin from '@/assets/mixins/titleMixin'

export default {
  name: 'Search',
  components: {
    RecipeCard
  },
  data () {
    return {
      query: ''
    }
  },
  props: {
    recipes: Array
  },
  computed: {
    foundRecipes: function () {
      const regEx = new RegExp(this.query, 'gi')
      return this.recipes.filter((recipe) => {
        return recipe.name.match(regEx) || recipe.category.match(regEx) || recipe.keywords.filter((k) => k.match(regEx)).length > 0
      })
    }
  },
  mixins: [titleMixin],
  title () {
    return 'Zoeken – Eten Bij Sindy'
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors.scss';

.search-form {
  height: 200px;
  margin-top: 48px;
  margin-bottom: 48px;
  background-color: $primaryColor;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    width: 480px;
    height: 48px;
    padding: 0 16px;
    border-radius: 8px 0 0 8px;
    border: 0;
    font-size: 1em;

    @include respond-to('xsmall') {
      width: 60%;
    }

    @include respond-to('small') {
      width: 60%;
    }

    @include respond-to('medium') {
      width: 480px;
    }
  }

  button {
    height: 48px;
    width: 54px;
    line-height: 48px;
    padding: 0 16px;
    border: 0;
    border-radius: 0 8px 8px 0;
    background-color: $actionColor;
    color: #fff;
    font-size: 1.25em;
    text-align: center;
  }
}

.recipe-list {
  display: grid;
  gap: 1em;
  margin-bottom: 4em;

  @include respond-to('xsmall') {
    grid-template-columns: 1fr;
  }

  @include respond-to('small') {
    grid-template-columns: 1fr 1fr;
  }

  @include respond-to('medium') {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
