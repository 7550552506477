<template>
  <div class="recipe-card" v-if="recipe">
    <router-link :to="'/recept/' + recipe.path">
      <img class="image" :src="getImg(recipe.image)" :alt="recipe.name" />
      <ul class="recipe-summary">
        <li>
          <span itemprop="recipeCategory"> <i :class="'fa fa-fw ' + setCategoryIcon(recipe.category)"> </i> {{ recipe.category }} </span>
        </li>
        <li>
          <span itemprop="totalTime"> <i class="fa fa-clock fa-fw"></i> {{ convertedTime }} </span>
        </li>
        <li>
          <span itemprop="recipeYield"> <i class="fa fa-user fa-fw"></i> {{ recipe.yield }} </span>
        </li>
      </ul>
      <h3>{{ recipe.name }}</h3>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'SuggestionCard',
  props: {
    recipe: Object
  },
  computed: {
    convertedTime: function () {
      const time = this.recipe.totalTime.match(/\d+/g)
      if (time.length > 1) {
        return time[0] + ' u' + time[1] + 'min.'
      } else {
        return time[0] + ' min.'
      }
    }
  },
  methods: {
    getImg (name) {
      const images = require.context('@/recipes/', true, /\.jpg|\.jpeg|\.png$/)
      try {
        return images('./' + this.recipe.path + '/' + name)
      } catch {
        return require('@/assets/img/404.jpg')
      }
    },
    setCategoryIcon (category) {
      switch (category) {
        case 'brood':
          return 'fa-bread-loaf'
        case 'dessert':
          return 'fa-ice-cream'
        case 'drankjes':
          return 'fa-glass'
        case 'gebak':
          return 'fa-pie'
        case 'groente':
          return 'fa-carrot'
        case 'koekjes':
          return 'fa-cookie'
        case 'snacks':
          return 'fa-popcorn'
        case 'soepen':
          return 'fa-soup'
        case 'vegetarisch':
          return 'fa-seedling'
        case 'vis':
          return 'fa-fish'
        case 'vlees':
          return 'fa-steak'
        default:
          return 'fa-utensils'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors.scss';

.recipe-card {
  box-shadow: 0 4px 8px #eee;

  &:hover {
    position: relative;
    top: 2px;
  }
}

h3 {
  @include headerFont;
  @include roundedCornersBottomSmall;
  font-size: 1.25em;
  padding: 0.75em;
  margin: 0;
  text-align: center;
  color: $primaryColor;
  background-color: #fff;
}

.image {
  width: 100%;
  height: 195px;
  object-fit: cover;
  @include roundedCornersTopSmall;
}

.recipe-summary {
  @include subheaderFont;
  margin: -6px 0 0 0;
  padding: 0;
  display: flex;
  justify-content: center;
  background-color: #eee;
  height: 40px;
  line-height: 40px;

  li {
    display: inline-block;
    margin-right: 0.5em;
    color: #666;

    &:first-of-type {
      text-transform: capitalize;
    }
  }
}
</style>
