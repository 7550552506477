<template>
  <div class="shopping-list-container">
    <div class="shopping-list-paper">
      <h1>Boodschappenlijst</h1>
      <ul v-if="shoppingList" class="shopping-list">
        <template v-for="(listItem, i) in shoppingList" v-bind:key="i">
          <li>
            <i class="far fa-fw fa-square"></i> <b>{{ listItem.name.charAt(0).toUpperCase() + listItem.name.slice(1) }}</b> <span class="extra-info">{{ listItem.amount }} {{ listItem.unit }}</span>
            <i @click="this.$emit('deleteShoppingListItem', listItem.name)" class="far fa-fw fa-trash-alt"></i>
          </li>
        </template>
      </ul>
      <p v-if="shoppingList.length === 0">Er staat nog niets op je boodschappenlijst!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShoppingList',
  components: {},
  props: {
    shoppingList: Array
  },
  emits: ['deleteShoppingListItem']
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors.scss';

.shopping-list-container {
  display: grid;
  justify-content: center;
  align-content: center;
}

.shopping-list-paper {
  @include roundedCornersLarge;
  background-color: #fff;
  box-shadow: 0 4px 16px rgba(#000000, 0.2);
  width: 640px;
  font-family: 'Courier New', Courier, monospace;
  padding: 0 0 4em 0;

  h1 {
    text-align: center;
    padding: 1em;
    border-bottom: 2px solid $primaryColor;
  }

  h2 {
    text-align: center;
  }

  p {
    text-align: center;
    margin-top: 3em;
  }
}

.shopping-list {
  margin: 0;
  list-style-type: none;
  padding-left: 1.5em;
  padding-right: 1.5em;

  li {
    font-size: 1.25em;
    border-bottom: 1px dashed #ccc;
    line-height: 2.25;
    cursor: pointer;

    b {
      // text-transform: capitalize;
    }

    i {
      margin: 0 8px 0 0px;
      color: $primaryColor;
    }

    .extra-info {
      color: #777;
    }
  }

  .fa-trash-alt {
    color: #999;
    float: right;
    display: block;
    margin: 12px 8px 8px 8px;
  }

  .crossed {
    text-decoration: line-through;
  }
}
</style>
