<template>
  <h1>{{ this.$route.params.name }}</h1>

  <div class="recipe-list">
    <template v-for="(recipe, i) in foundRecipes" v-bind:key="i">
      <RecipeCard :recipe="recipe" />
    </template>
  </div>
</template>

<script>
import RecipeCard from '@/components/RecipeCard.vue'

export default {
  name: 'Category',
  components: {
    RecipeCard
  },
  data () {},
  props: {
    recipes: Array
  },
  computed: {
    foundRecipes: function () {
      return this.recipes.filter((recipe) => recipe.category.toLowerCase() === this.$route.params.name.toLowerCase() || recipe.keywords.includes(this.$route.params.name))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors.scss';

h1 {
  @include subheaderFont;
  margin-top: 2.75em;
  margin-bottom: 1em;
  border-top: 1px solid $primaryColor;
  border-bottom: 1px solid $primaryColor;
  padding: 0.25em;
  color: $primaryColor;
  text-align: center;
  text-transform: capitalize;
  font-weight: normal;
}

.search-form {
  height: 200px;
  margin-top: 48px;
  margin-bottom: 48px;
  background-color: $primaryColor;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    height: 48px;
    padding: 0 16px;
    border-radius: 8px 0 0 8px;
    border: 0;
    font-size: 1em;
  }

  button {
    height: 48px;
    width: 54px;
    line-height: 48px;
    padding: 0 16px;
    border: 0;
    border-radius: 0 8px 8px 0;
    background-color: $actionColor;
    color: #fff;
    font-size: 1.25em;
    text-align: center;
  }
}
</style>
