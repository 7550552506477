<template>
  <article itemscope itemtype="https://schema.org/Recipe" v-if="recipe">
    <header>
      <img itemprop="image" :src="getImg(recipe.image)" class="recipe-image" />
      <div class="recipe-summary">
        <h1 itemprop="name">{{ recipe.name }}</h1>
        <ul>
          <li>
            <span itemprop="recipeCategory"> <i :class="'fas fa-fw ' + setCategoryIcon(recipe.category)"></i> {{ recipe.category }} </span>
          </li>
          <li>
            <span itemprop="totalTime" :content="recipe.totalTime"> <i class="fa fa-clock fa-fw"></i> {{ convertedTime }}</span>
          </li>
          <li>
            <span itemprop="recipeYield"> <i class="fa fa-user fa-fw"></i> {{ recipe.yield }} {{ recipe.yieldUnit }} </span>
          </li>
        </ul>
        <div itemprop="description" class="no-print" v-html="recipe.description"></div>
        <!-- <button v-if="addedToShoppingList === false" @click="addRecipeToShoppingList(recipe)" class="no-print" title="Voeg ingrediënten toe aan boodschappenlijst">
          <i class="fa fa-fw fa-plus"></i> Boodschappenlijst
        </button>
        <button v-else class="no-print status-true" title="De ingrediënten zijn toegevoegd aan de boodschappenlijst!"><i class="fa fa-fw fa-check"></i> Toegevoegd</button> -->
        <button class="no-print" title="Print recept" onclick="window.print();return false;"><i class="fa fa-fw fa-print"></i> Print</button>
      </div>
    </header>

    <h2>Instructies & ingrediënten</h2>
    <section class="recipe-step" :class="{ 'no-image': !step.image }" v-for="(step, i) in recipe.steps" v-bind:key="i">
      <h3>{{ step.name }}</h3>
      <img v-if="step.image" itemprop="image" :src="getImg(step.image)" :alt="step.alt" />
      <div>
        <ul class="ingredients-list">
          <template v-for="(ingredient, i) in step.ingredients" v-bind:key="i">
            <IngredientListItem :ingredient="ingredient"></IngredientListItem>
          </template>
        </ul>
        <ol class="instructions-list">
          <template v-for="(instruction, i) in step.instructions" v-bind:key="i">
            <li itemprop="recipeInstructions" v-html="highlightIngredients(instruction, step.ingredients)"></li>
          </template>
        </ol>
      </div>
    </section>

    <section class="recipe-tips" v-if="recipe.tips">
      <h3>Extra notities en tips</h3>
      <ul>
        <template v-for="(tip, i) in recipe.tips" v-bind:key="i">
          <li v-html="tip"></li>
        </template>
      </ul>
    </section>

    <section class="no-print recipe-source" v-if="recipe.isBasedOn">
      Gebaseerd op een recept van <a :href="recipe.isBasedOnUrl" target="_blank">{{ recipe.isBasedOn }}</a>
    </section>

    <section class="recipe-keywords no-print">
      <h4>Meer uit deze categorie:</h4>
      <ul itemprop="keywords">
        <li>
          <router-link :to="'/recepten/' + recipe.category">{{ recipe.category }}</router-link>
        </li>
        <li v-for="(keyword, i) in recipe.keywords" v-bind:key="i">
          <router-link :to="'/recepten/' + keyword">{{ keyword }}</router-link>
        </li>
      </ul>
    </section>

    <h2 class="no-print">Bekijk ook eens deze recepten</h2>
    <div class="suggestion-list no-print">
      <template v-for="(recipe, i) in suggestedRecipes.slice(0, 4)" v-bind:key="i">
        <SuggestionCard :recipe="recipe" />
      </template>
    </div>
  </article>
  <article v-else>
    <div class="not-found">
      <h1>Recept niet gevonden</h1>
      <p>Helaas, het recept dat je probeert te bekijken bestaat niet.</p>
      <router-link to="/recepten" class="rounded-button">Terug naar receptoverzicht</router-link>
    </div>
  </article>
</template>

<script>
import SuggestionCard from '@/components/SuggestionCard.vue'
import IngredientListItem from '@/components/IngredientListItem.vue'
import titleMixin from '@/assets/mixins/titleMixin'

export default {
  name: 'Recipe',
  props: {
    recipes: Array,
    shoppingList: Array
  },
  data () {
    return {
      addedToShoppingList: false
    }
  },
  emits: ['addRecipeToShoppingList'],
  components: {
    SuggestionCard,
    IngredientListItem
  },
  computed: {
    recipe: function () {
      return this.recipes.find((recipe) => recipe.path === this.$route.params.name)
    },
    suggestedRecipes: function () {
      const regEx = new RegExp(this.recipe.category, 'gi')
      const suggestions = this.recipes.filter((recipe) => recipe.category.match(regEx) && recipe !== this.recipe)

      if (suggestions.length > 0) {
        return suggestions
      }
      return this.recipes
    },
    convertedTime: function () {
      const time = this.recipe.totalTime.match(/\d+/g)
      if (time.length > 1) {
        return time[0] + ' uur ' + time[1] + ' minuten'
      } else {
        return time[0] + ' minuten'
      }
    }
  },
  methods: {
    toggleIngredient (ingredient) {
      ingredient.done = !ingredient.done
    },
    hoverIngredient (ingredient, status) {
      ingredient.hover = status
    },
    getImg (name) {
      const images = require.context('@/recipes/', true, /\.jpg|\.jpeg|\.png$/)
      try {
        return images('./' + this.recipe.path + '/' + name)
      } catch {
        return require('@/assets/img/404.jpg')
      }
    },
    setCheckboxStatus (ingredient) {
      if (ingredient.hover) {
        return 'fas fa-check-square'
      } else if (ingredient.done) {
        return 'fas fa-check-square'
      } else {
        return 'far fa-square'
      }
    },
    highlightIngredients (instruction, ingredients) {
      let highlightedInstruction = instruction
      if (ingredients) {
        ingredients.forEach((ingredient) => {
          highlightedInstruction = highlightedInstruction.replace(' ' + ingredient.name + ' ', ' <b>' + ingredient.name + '</b> ')
          highlightedInstruction = highlightedInstruction.replace(' ' + ingredient.name + ', ', ' <b>' + ingredient.name + '</b>, ')
          highlightedInstruction = highlightedInstruction.replace(' ' + ingredient.name + '.', ' <b>' + ingredient.name + '</b>.')
        })
      }
      highlightedInstruction = highlightedInstruction.replaceAll(/[0-9]+°C.+\)/g, '<b>$&</b>')
      highlightedInstruction = highlightedInstruction.replaceAll(/([0-9]+.)*[0-9]+\s(uur|minuut|minuten|seconden|eetlepels|eetlepel)/g, '<b>$&</b>')

      return highlightedInstruction
    },
    setCategoryIcon (category) {
      switch (category) {
        case 'brood':
          return 'fa-bread-loaf'
        case 'dessert':
          return 'fa-ice-cream'
        case 'drankjes':
          return 'fa-glass'
        case 'gebak':
          return 'fa-pie'
        case 'groente':
          return 'fa-carrot'
        case 'koekjes':
          return 'fa-cookie'
        case 'snacks':
          return 'fa-popcorn'
        case 'soepen':
          return 'fa-soup'
        case 'vegetarisch':
          return 'fa-seedling'
        case 'vis':
          return 'fa-fish'
        case 'vlees':
          return 'fa-steak'
        default:
          return 'fa-utensils'
      }
    },
    addRecipeToShoppingList (recipe) {
      console.log(this.addedToShoppingList)

      if (this.addedToShoppingList === false) {
        this.$emit('addRecipeToShoppingList', recipe)
        this.addedToShoppingList = true
      }
    }
  },
  mixins: [titleMixin],
  title () {
    return `${this.recipe.name} – Eten Bij Sindy`
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors.scss';

@media screen {
  .not-found {
    height: 19em;
    background-color: #fff;
    border: 1px solid $greyDark;
    border-radius: 8px;
    text-align: center;
    box-shadow: 2px 2px 4px rgba(#000000, 0.4);

    h1 {
      margin-top: 3em;
      color: $primaryColor;
    }

    a {
      margin-top: 1em;
    }
  }

  header {
    margin-top: 1em;
    min-height: 480px;
    display: grid;

    .recipe-image {
      @include roundedCornersTopSmall;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .recipe-summary {
      @include roundedCornersBottomSmall;
      background-color: $primaryColor;
      color: #fff;
      padding: 1em 2em;

      h1 {
        @include headerFont;
        margin: 0.5em 0 0.25em 0;
        font-size: 2.25em;
        font-weight: normal;
      }

      ul {
        @include headerFont;
        font-weight: normal;
        margin: 0;
        padding: 0;

        li {
          display: inline;
          margin-right: 8px;

          &:first-of-type {
            text-transform: capitalize;
          }
        }
      }

      button {
        @include roundedCornersLarge;
        padding: 8px 16px;
        display: inline-block;
        margin-top: 1em;
        margin-right: 8px;
        margin-bottom: 2em;
        font-size: 0.9em;
        font-weight: bold;
        border: 2px solid #fff;
        color: #fff;
        background-color: $primaryColor;
        cursor: pointer;

        &:hover {
          background-color: rgba(#fff, 0.1);
        }

        &:active {
          position: relative;
          top: 1px;
        }

        &.status-true {
          color: white;
          background-color: green;
          cursor: default;
        }

        &.status-true:active {
          top: 0px;
        }
      }
    }
  }

  h2 {
    @include headerFont;
    color: $primaryColor;
    background-color: $primaryColorDark;
    border-radius: 8px;
    color: #fff;
    padding: 0.5em;
    text-align: center;
    font-size: 1.5em;
  }

  .recipe-step {
    display: grid;
    margin-bottom: 1.5em;
    background-color: $grey;
    border-radius: 16px;
    border: 1px solid $greyDark;

    h3 {
      @include headerFont;
      grid-column: 1 / -1;
      color: $primaryColor;
      font-size: 1.5em;
    }

    img {
      width: 100%;
      margin-bottom: 1em;
      border-radius: 8px;
    }

    .ingredients-list {
      margin: 0;
      list-style-type: none;
      padding-left: 6px;
    }

    .instructions-list {
      margin: 0;
      list-style: none;
      overflow: hidden;
      counter-reset: numList;

      li {
        margin: 1em 0;
        position: relative;

        &:before {
          counter-increment: numList;
          content: counter(numList);
          position: absolute;
          left: -32px;
          top: 2px;
          font: bold 12px sans-serif;
          text-align: center;
          color: #fff;
          line-height: 20px;
          width: 20px;
          height: 20px;
          background: $primaryColor;
          -moz-border-radius: 50%;
          border-radius: 50%;
        }
      }
    }
  }

  .recipe-step.no-image {
    grid-template-columns: 1fr;
  }

  .recipe-tips {
    background-color: $grey;
    border-radius: 16px;
    border: 1px solid $greyDark;
    padding: 1em 2em 2em;
    margin-bottom: 1.5em;
    color: 666;

    h3 {
      @include headerFont;
      color: $primaryColor;
      font-size: 1.5em;
    }

    @include respond-to('xsmall') {
      padding: 1em 1em 2em;
    }
  }

  .recipe-list {
    display: grid;
    gap: 1em;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .recipe-keywords {
    padding: 0.5em;
    border-radius: 8px;
    border: 1px solid $greyDark;
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;

    h4 {
      margin: 0 0.75em 0 1em;
      display: inline-block;
      line-height: 40px;
      color: #333;
    }

    ul {
      display: inline-block;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin: 0.25em 0.25em;

        a {
          display: inline-block;
          background-color: $primaryColorExtralight;
          border-radius: 8px;
          font-weight: bold;
          color: #fff;
          padding: 0.25em 0.5em;

          &:hover {
            background-color: $primaryColorLight;
          }
        }
      }
    }
  }

  .recipe-source {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    margin-bottom: 1.5em;

    a {
      margin-left: 5px;
    }
  }

  .suggestion-list {
    display: grid;
    gap: 1em;
    margin-bottom: 2em;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .recipe-step {
      padding: 1em;

      h3 {
        margin: 0.5em 0 1em 0;
      }
    }

    .suggestion-list {
      grid-template-columns: 1fr;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .recipe-step {
      display: grid;
      grid-template-columns: 1fr;
      padding: 2em;

      h3 {
        margin: 0em 0 1em 0;
      }

      img {
        height: auto;
        width: 100%;
      }
    }

    .recipe-keywords {
      display: flex;
      justify-content: center;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    header {
      grid-template-columns: 1fr 1fr;

      .recipe-image {
        border-radius: 16px 0 0 16px;
      }

      .recipe-summary {
        border-radius: 0 16px 16px 0;
        margin-top: 0;
      }
    }

    .suggestion-list {
      grid-template-columns: 1fr 1fr;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .header {
      box-shadow: 2px 2px 4px rgba($primaryColor, 0.4);
    }

    .recipe-step {
      grid-template-columns: 1fr 2fr;

      div {
        margin-left: 16px;
      }
    }

    .suggestion-list {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .suggestion-list {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}

@media print {
  @page {
    margin: 25mm 20mm;
  }

  * {
    font-family: Arial, Helvetica, sans-serif;
  }

  .fa,
  .fas {
    font-family: 'Font Awesome 5 Pro';
  }

  header {
    .recipe-image {
      height: 100px;
      float: right;
      margin-top: 0.75em;
    }
  }

  .recipe-summary {
    ul {
      margin: 0;
      padding: 0;

      li {
        display: inline;
        margin-right: 8px;

        &:first-of-type {
          text-transform: capitalize;
        }
      }
    }
  }

  h1 {
    font-size: 1.75em;
    margin-bottom: 0.5em;
  }

  h2 {
    font-size: 1.5em;
    margin-bottom: 0;
  }

  .recipe-step {
    img {
      display: none !important;
    }

    i {
      margin-right: 8px;
    }
  }
}
</style>
