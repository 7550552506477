<template>
  <router-link :to="'/recepten/' + category">
    <div class="image">
      <div class="circle"></div>
      <i :class="'fas fa-fw ' + setCategoryIcon(category)"></i>
    </div>
    <h3>{{ category }}</h3>
  </router-link>
</template>

<script>
export default {
  name: 'CategoryCard',
  props: {
    category: String,
    url: String,
    recipes: Array
  },
  computed: {
    lastRecipe: function () {
      return this.recipes.find((recipe) => recipe.category.toLowerCase() === this.category || recipe.keywords.includes(this.category))
    }
  },
  methods: {
    getLastRecipeImg () {
      const images = require.context('@/recipes/', true, /\.jpg|\.jpeg|\.png$/)
      try {
        return images('./' + this.lastRecipe.path + '/' + this.lastRecipe.image)
      } catch {
        return require('@/assets/img/404.jpg')
      }
    },
    setCategoryIcon (category) {
      switch (category) {
        case 'brood':
          return 'fa-bread-loaf'
        case 'dessert':
          return 'fa-ice-cream'
        case 'drankjes':
          return 'fa-glass'
        case 'gebak':
          return 'fa-pie'
        case 'groente':
          return 'fa-carrot'
        case 'koekjes':
          return 'fa-cookie'
        case 'snacks':
          return 'fa-popcorn'
        case 'soepen':
          return 'fa-soup'
        case 'vegetarisch':
          return 'fa-seedling'
        case 'vis':
          return 'fa-fish'
        case 'vlees':
          return 'fa-steak'
        case 'saus':
          return 'fa-soup'
        default:
          return 'fa-utensils'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors.scss';

h3 {
  @include roundedCornersBottomLarge;
  @include headerFont;
  height: 48px;
  margin: 0;
  margin-top: -6px;
  line-height: 48px;
  text-align: center;
  background-color: $primaryColor;
  color: #fff;
  text-transform: capitalize;
  font-size: 1.5em;
}

.image {
  @include roundedCornersTopLarge;
  background-color: $primaryColorLight;
  width: 100%;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;

  .circle {
    background-color: $primaryColor;
    height: 180px;
    width: 180px;
    border-radius: 90px;
    display: block;
    position: absolute;
    z-index: 1;
  }

  i {
    color: rgba(255, 255, 255, 0.95);
    font-size: 100px;
    position: absolute;
    z-index: 2;
  }
}

a:hover {
  .image {
    background-color: $primaryColorExtralight;
  }

  .circle {
    background-color: $primaryColorLight;
  }

  h3 {
    background-color: $primaryColorLight;
  }
}
</style>
