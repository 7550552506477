<template>
  <!-- <div class="search-container">
    <h2>Filter recepten</h2>
    <form class="search-form" action="" type="submit">
      <select name="category">
        <option value="" disabled>Per categorie</option>
        <option value="">Dessert</option>
        <option value="">Brood</option>
        <option value="">Drankjes</option>
        <option value="">Gebak & koekjes</option>
        <option value="">Groente</option>
        <option value="">Snacks</option>
        <option value="">Soepen</option>
        <option value="">Pasta</option>
        <option value="">Vis</option>
        <option value="">Vegetarisch</option>
        <option value="">Vlees</option>
      </select>
      <select name="ingredient" v-model="ingredient">
        <option value="" disabled>Per ingrediënt</option>
        <option value="kip">Kip</option>
        <option value="spek">Spek</option>
        <option value="gehakt">Gehakt</option>
        <option value="groente">Groente</option>
      </select>
      <select name="cuisine">
        <option value="" disabled>Per keuken</option>
        <option value="">Chinees</option>
        <option value="">Italiaans</option>
        <option value="">Indiaas</option>
        <option value="">Japans</option>
        <option value="">Koreaans</option>
        <option value="">Mediterraans</option>
        <option value="">Thais</option>
        <option value="">Westers</option>
      </select>
    </form>
  </div> -->

  <div class="search-container">
    <h1>Recepten</h1>
    <p>Bekijk hier al mijn favoriete recepten per categorie!</p>
  </div>

  <div class="category-list">
    <div class="recipe-card"><CategoryCard :recipes="recipes" category="vegetarisch" /></div>
    <div class="recipe-card"><CategoryCard :recipes="recipes" category="groente" /></div>
    <div class="recipe-card"><CategoryCard :recipes="recipes" category="vlees" /></div>
    <div class="recipe-card"><CategoryCard :recipes="recipes" category="vis" /></div>
    <div class="recipe-card"><CategoryCard :recipes="recipes" category="pasta" /></div>
    <div class="recipe-card"><CategoryCard :recipes="recipes" category="gebak" /></div>
    <div class="recipe-card"><CategoryCard :recipes="recipes" category="koekjes" /></div>
    <div class="recipe-card"><CategoryCard :recipes="recipes" category="dessert" /></div>
    <div class="recipe-card"><CategoryCard :recipes="recipes" category="saus" /></div>
  </div>

  <div class="recipe-list">
    <template v-for="(recipe, i) in foundRecipes" v-bind:key="i">
      <RecipeCard :recipe="recipe" />
    </template>
  </div>
</template>

<script>
// @ is an alias to /src
import CategoryCard from '@/components/CategoryCard.vue'
import RecipeCard from '@/components/RecipeCard.vue'
import titleMixin from '@/assets/mixins/titleMixin'

export default {
  name: 'Recipes',
  props: {
    recipes: Array
  },
  data () {
    return {
      ingredient: ''
    }
  },
  components: {
    CategoryCard,
    RecipeCard
  },
  computed: {
    foundRecipes: function () {
      return this.recipes.filter((recipe) => recipe.keywords.includes(this.ingredient))
    }
  },
  mixins: [titleMixin],
  title () {
    return 'Recepten – Eten Bij Sindy'
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors.scss';

.search-container {
  background-color: $primaryColor;
  padding: 1em 0 2em 0;
  margin-top: 48px;
  margin-bottom: 48px;
  border-radius: 16px;
  color: #fff;
  text-align: center;

  h1 {
    @include headerFont;
  }
}

.search-form {
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    margin-right: 8px;
  }

  select {
    height: 48px;
    padding: 0 16px;
    margin-right: 8px;
    border-radius: 8px;
    border: 0;
    font-size: 1em;
  }

  button {
    height: 48px;
    width: 54px;
    line-height: 48px;
    padding: 0 16px;
    border: 0;
    border-radius: 8px;
    background-color: $actionColor;
    color: #fff;
    font-size: 1.25em;
    text-align: center;
  }
}

.category-list {
  display: grid;
  gap: 24px;

  @include respond-to('xsmall') {
    grid-template-columns: 1fr;
  }

  @include respond-to('small') {
    grid-template-columns: 1fr 1fr;
  }

  @include respond-to('large') {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include respond-to('xlarge') {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.recipe-list {
  display: grid;
  gap: 1em;
  grid-template-columns: 1fr;
}
</style>
