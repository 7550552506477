<template>
  <div class="introduction">
    <h1>Tips & trucs</h1>
    <p>Binnenkort kun je hier mijn beste tips en trucs vinden om het koken en bakken gemakkelijker te maken!</p>
  </div>
  <!-- <div class="recipe-list">
    <template v-for="(tip, i) in tips" v-bind:key="i">
      <TipCard :tip="tip" />
    </template>
  </div> -->
</template>

<script>
// @ is an alias to /src
// import TipCard from '@/components/TipCard.vue'
import titleMixin from '@/assets/mixins/titleMixin'

export default {
  name: 'Home',
  components: {
    // TipCard
  },
  props: {
    tips: Array
  },
  mixins: [titleMixin],
  title () {
    return 'Tips & trucs – Eten Bij Sindy'
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/colors.scss';

.introduction {
  margin: 3em 0;
  padding: 1em 0 2em 0;
  background-color: $primaryColor;
  border-radius: 16px;
  color: #fff;
  text-align: center;

  h1 {
    @include headerFont;
  }
}
</style>

<script>
</script>
